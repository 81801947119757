@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,300;0,400;1,300&display=swap);
html,body{margin:0;padding:0; overflow-x: hidden;}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}





.constructionPage {
    width: 100vw;
    height: 100vh;
    background-color: #FDF9EF;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;


    font-family: Montserrat;
    font-weight: 600;
    font-size: 48px;
    color: black;
    z-index: 5;
}

.constructionPageSubtext {
    /* display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; */
    padding: 0px 30px;


    font-family: Montserrat;
    font-weight: 300;
    font-size: 24px;
    color: black;
    z-index: 5;
}

.emoji {
  font-size: 16px;
}

.strikethrough {
  position: relative;
  display: block;
  cursor: pointer;

  font-family: Montserrat;
  font-weight: 300;
  font-size: 16px;
  color: black;
  z-index: 5;
}

.strikethrough:before,
.strikethrough:after {
  content: '';
  position: absolute;
  width: 0%;
  height: 2px;
  top: 50%;
  margin-top: -2.5px;
  background: #fff;
}

.strikethrough:before {
  left: -2.5px;
}
.strikethrough:after {
  right: 2.5px;
  background: black;
  transition: width 0.8s cubic-bezier(0.22, 0.61, 0.36, 1);
}

.strikethrough:hover:before {
  background: black;
  width: 100%;
  transition: width 0.5s cubic-bezier(0.22, 0.61, 0.36, 1);
}

.strikethrough:hover:after {
  background: transparent;
  width: 100%;
  transition: 0s;
}

.blackSection {
    background-color: black;
    width: 100vw;
    height: 300px;
    position: absolute;
    bottom: 0px;
    z-index: 0;
}

.landingBackground {
    height: 100vh;
    background-color: white;
    overflow: hidden;
}

.pulse {
    -webkit-animation: pulse 1.5s infinite;
            animation: pulse 1.5s infinite;
    margin: 0 auto;
    display: table;
    margin-top: 50px;
    -webkit-animation-direction: alternate;
            animation-direction: alternate;
    -webkit-animation-name: pulse;
    animation-name: pulse;
}

@-webkit-keyframes pulse {
    0% {
        -webkit-transform: scale(1);
    }
    50% {
        -webkit-transform: scale(1.1);
    }
    100% {
        -webkit-transform: scale(1);
    }
}

@keyframes pulse {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.1);
    }
    100% {
        transform: scale(1);
    }
}

@media only screen and (max-width: 1000px) {
    .description {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 1.5vw;
        letter-spacing: 0.06em;
        color: #000000;
        position: relative;
        width: 30%;
        height: 120px;
        left: 20vw;
        top: -10px;
        z-index: 5;
    }
}
@media only screen and (min-width: 1000px) {
    .description {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 1.5vw;
        letter-spacing: 0.06em;
        color: #000000;
        position: relative;
        width: 30%;
        height: 120px;
        left: 20vw;
        top: -10px;
        z-index: 5;
    }
}

.landingText {
    position: absolute;
    top: 150px;
}

.heading {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 2vw;
    letter-spacing: 0.06em;
    text-transform: uppercase;
    position: relative;
    width: 100%;
    height: 100%;
    left: 20vw;
    top: 20%;
    z-index: 5;
}

.boston {
    position: absolute;
    width: 50%;
    height: 100%;
    /* top: 0px; */
    /* min-width: 100%; */
    /* width: 60vw; */
    right: 0px;
    bottom: 0px;
    z-index: 1;
}

.blueOverlay {
    position: absolute;
    width: 60%;
    height: 400px;
    right: -10%;
    bottom: 0px;
    z-index: 3;

    background: linear-gradient(115.23deg, #153145 22.09%, rgba(255, 255, 255, 0) 95.11%), #0e88de;
    mix-blend-mode: multiply;
    transform: rotate(-180deg);
}

.blueDots {
    position: absolute;
    /* width: 560px;
    height: 560px; */
    left: 40%;
    /* right: 0%; */
    top: 40%;
    z-index: -10;
}

.options {
    display: inline-block;
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 18px;
    text-transform: uppercase;
    letter-spacing: 0.06em;
    color: #000000;
    /* color: #ffffff; */
    padding: 8px 0px 8px 16px;
    text-decoration: none;
    /* color: rgba(0, 0, 0, 0.25); */
    z-index: 5;
}

.options a {
    color: inherit;
    text-decoration: none;
}

.menu {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    /* or 150% */
    letter-spacing: 0.06em;
    /* color: #000000; */
    /* position: relative;
	width: 100%;
	height: 100%;
	left: 30%;
	top: 40%; */
    position: absolute;
    /* position: fixed; */
    width: 26%;
    height: 120px;
    left: 5%;
    bottom: 350px;
    z-index: 5;
}

.scroll {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    text-transform: lowercase;
    /* identical to box height, or 112% */

    letter-spacing: 0.06em;
    display: block;
    margin: -25px auto 0px auto;
}

.scrollContainer {
    text-align: center;
    position: absolute;
    /* width: 26%; */
    height: 120px;
    left: 20%;
    bottom: 200px;
    z-index: 5;
}

/* .nameCol {
    display: flex;
    flex-direction: column;
    position: absolute;
    right: 550px;
    top: 0;
    z-index: 5;
} */

/* .nameCol :last-child {
    margin-top: 40px;
} */

.nameRow {
    display: flex;
    flex-direction: row;
    position: absolute;
    /* width: 26%; */
    /* height: 120px; */
    /* right: 0px; */
    top: 190px;
    right: 20px;
    z-index: 5;
}

.nameRow :first-child {
    margin-right: 100px;
    /* margin: -25px auto 0px auto; */
}

.letterCol {
    width: 140px;
}
.letterRow {
    height: 180px;
}

.lastName {
    display: flex;
    flex-direction: row;
    position: absolute;
    /* width: 26%; */
    /* height: 120px; */
    /* right: 0px; */
    bottom: 150px;
    right: 0px;
    z-index: 5;
}

.lastNameRow {
    height: 180px;
    margin-right: 80px;
}

.firstName {
    position: absolute;
    z-index: 5;
    top: 0px;
    right: 0px;
    display: grid;
    grid-gap: 20px;
    align-items: center;
    grid-template-columns: 10vw 10vw 10vw 10vw;
    justify-items: center;
    grid-template-areas:
        'e . . .'
        'r . i c';
}

.firstName img {
    display: inline-block;
    vertical-align: middle;
}

.e-eric {
    grid-area: e;
    width: 100%;
}

.r-eric {
    grid-area: r;
    width: 100%;
}
.i-eric {
    grid-area: i;
    width: 40%;
}
.c-eric {
    grid-area: c;
    width: 100%;
}

.lastName {
    position: absolute;
    right: 0px;
    z-index: 5;
    display: grid;
    grid-gap: 20px;
    align-items: baseline;
    grid-template-columns: 10vw 10vw 10vw 5vw;
    justify-items: center;
    grid-template-areas: 'k i-kim m';
}

.k-kim {
    grid-area: k;
    width: 100%;
}
.i-kim {
    grid-area: i-kim;
    width: 33%;
}
.m-kim {
    grid-area: m;
    width: 115%;
}

.strikethrough {
    position: relative;
    display: block;
    cursor: pointer;
}

.strikethrough:before,
.strikethrough:after {
    content: '';
    position: absolute;
    width: 0%;
    height: 2px;
    top: 50%;
    margin-top: -2.5px;
    background: #fff;
}

.strikethrough:before {
    left: -2.5px;
}
.strikethrough:after {
    right: 2.5px;
    background: black;
    transition: width 0.8s cubic-bezier(0.22, 0.61, 0.36, 1);
}

.strikethrough:hover:before {
    background: black;
    width: 100%;
    transition: width 0.5s cubic-bezier(0.22, 0.61, 0.36, 1);
}

.strikethrough:hover:after {
    background: transparent;
    width: 100%;
    transition: 0s;
}

.workBackground {
    /* padding-top: 10vw; */
    /* height: 100vh; */
    background-color: black;
}

.workContainer {
    padding-top: 5vw;
    height: 100%;
    background-color: black;
}

.work {
    padding: 10px 0px 0px 10vw;
    display: grid;
    grid-gap: 3vw;
    align-items: center;
    grid-template-columns: 80px 80px 80px 80px;
    justify-items: center;
    grid-template-areas:
        'w . . . .'
        'o dash r k .';
}

.work img {
    display: inline-block;
    vertical-align: middle;
}

.w {
    grid-area: w;
    /* transform-style: preserve-3d;
    transition: all 1s linear; */
    width: 6vw !important;
}
/* .w:hover {
    transform: rotateY(180deg);
    grid-area: w;
} */
.o {
    grid-area: o;
    width: 5vw !important;
}
.dash {
    grid-area: dash;
    width: 5vw !important;
}
.r {
    grid-area: r;
    width: 5vw !important;
}
.k {
    grid-area: k;
    width: 4vw !important;
}

.workCarousel {
    position: relative;
    height: 50vw;
}

.workNumber {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 900;
    font-size: 10vw;
    color: white;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: white;
    -webkit-text-fill-color: transparent;
    /* display: block;  */

    display: inline-block;
    margin-left: auto;
    margin-right: auto;
    width: 58%;
    position: absolute;
    top: -22%;
}

.workDescription {
    position: relative;
    text-align: left;
    width: 100%;
    top: 10vw;
    left: 11vw;
}

.workPosition {
    font-family: Roboto;
    font-style: italic;
    font-weight: 300;
    font-size: 3vw;
    color: #ffffff;
    z-index: 5;
}

.workName {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 900;
    font-size: 10vw;
    color: #ffffff;
    position: relative;
    z-index: 5;
}

.workImage {
    width: 50vw;
    position: absolute;
    top: 3vw;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
    z-index: 0;
}

.workDots {
    /* display: inline-block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
    position: absolute;
    top: 0px */
    /* 
    left: 50%;
    top: 0px; */
}

.imageOverlay {
    background-image: url(/static/media/workDotsFull.a6813c16.png);
    background-position: calc(50% + 18%) 90%;
    background-repeat: no-repeat;
    background-size: 40% 100%;
    min-height: 100;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 10px;
}

.workViewButton {
    font-family: Roboto;
    font-style: normal;
    font-weight: 300;
    font-size: 1vw;
    /* line-height: 23px; */
    display: block;
    background-color: #000000; /* Green */
    text-transform: lowercase;
    border: none;
    color: white;
    margin: 2vw 0px 0px 0px;
    text-align: center;
    text-decoration: none;
    padding: 0px;
    outline: none;
}

.workButtonUnderline {
    padding-top: 5px;
    display: block;
    width: 6vw;
}

.workStatus {
    display: inline-block;
    margin: 5vw 16px 0px 0px;
}

.workTooltip {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    text-align: center;
    color: #ffffff;
    margin: 0px;
}

.workStatus .workTooltip {
    visibility: hidden;
}

.workStatus:hover .workTooltip {
    visibility: visible;
}

.workCardTags {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 2.5vw 0rem 0rem 2rem;
    
    position: absolute;
    /* text-align: right; */
    /* width: 33%; */
    top: -25%;
    right: 18%;
    z-index: 10px
    /* left: 11vw; */
}

.workTag {
    border: 1px solid white;
    border-radius: 50px;
    padding: 5px 20px 5px 20px;
    margin: 5px 10px 0px 0px;
    text-align: center;
    display: flex;
    align-content: center;
    overflow: hidden;
}

.workTagName {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 1vw;
    white-space: nowrap;
    color: white;
    text-transform: uppercase;
    margin: 0px 0px 0px 10px;
}

.landingBackgroundProject {
    position: relative;
}

.projectCardContainer {
    padding: 20vw 10vw 15vw 10vw;
    display: flex;
    top: 5vw;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    z-index: 10;
}

.projects {
    padding: 5vw 5vw 0px 10vw;
    position: absolute;
    right: 0px;
    display: grid;
    grid-gap: 3vw 3vw;
    align-items: center;
    grid-template-columns: 80px 80px 80px 80px 80px;
    grid-template-rows: auto auto auto auto auto;
    justify-items: center;
    grid-template-areas:
        'p r o dash j'
        '. . . . e'
        '. . . . c'
        '. . . . t'
        '. . . . s'; 
    /* z-index: -5; */
}

.projects img {
    display: inline-block;
    vertical-align: middle;
}

.p {
    grid-area: p;
    width: 4vw !important;
}
.r {
    grid-area: r;
    width: 4vw !important;
}
.o {
    grid-area: o;
    width: 5vw !important;
}
.j {
    grid-area: j;
    width: 4vw !important;
}
.e {
    grid-area: e;
    width: 4vw !important;
}
.c {
    grid-area: c;
    width: 4vw !important;
}
.t {
    grid-area: t;
    width: 4vw !important;
}
.s {
    grid-area: s;
    width: 4vw !important;
}
.dash {
    grid-area: dash;
    width: 4vw !important;
}

.projectCard {
    position: relative;
    margin: 10px 10px 5rem 10px;
    z-index: 20;
}
.projectCardHover {
    position: relative;
    top: -80px;
    margin: 10px 10px 0px 10px;
    /* z-index: 20; */
}

.projectCardHoverBackground {
    position: absolute;
    left: 10px;
    top: 10px;
    width: 100%;
    height: 100%;
    background: url(/static/media/redBackground.66a038cb.png);
    /* height: 450px; */
    z-index: -20;
}

.projectCardNumber {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 900;
    font-size: 4vw;

    text-align: center;
    letter-spacing: 0.1em;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: black;
    -webkit-text-fill-color: transparent;

    position: relative;
    text-align: right;
    top: -10px;
}

@media only screen and (max-width: 1300px) {
    .projectCardBackground {
        position: relative;
        background-color: black;
        flex-basis: 50%;
        color: white;
        height: 35vw;
        width: 20vw;
        margin: 10px 10px 4rem 10px;
    }
}

@media only screen and (min-width: 1300px) {
    .projectCardBackground {
        position: relative;
        background-color: black;
        flex-basis: 33.33%;
        color: white;
        height: 25vw;
        width: 20vw;
    }
}

.projectCardImage {
    border-radius: 50%;
    height: 50px;
    width: 50px;
    margin: 1vw 0rem 0rem 2rem;
}

.projectCardImageHover {
    border-radius: 50%;
    height: 7vw;
    width: 7vw;
    margin: 1vw 0rem 0rem 2rem;
    position: relative;
    top: -6vw;
}

.projectHoverTest {
    position: relative;
    top: -5vw;
}

.projectCardTitle {
    color: white;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 900;
    font-size: 2vw;
    line-height: 2vw;
    text-transform: uppercase;
    padding: 1vw 0rem 0rem 2rem;
    z-index: 10;
}

.projectCardDesc {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 100;
    font-size: 1vw;
    line-height: 1.5vw;
    padding: 2vw 2rem 0rem 2rem;
}

.projectCardCaseButton {
    font-family: Roboto;
    font-style: normal;
    font-weight: 300;
    /* font-size: vw;  */
    /* line-height: 23px; */
    display: block;
    background-color: #000000; /* Green */
    text-transform: lowercase;
    border: none;
    color: white;
    text-align: left;
    text-decoration: none;
    padding: 0px;
    outline: none;

    position: relative;
    bottom: 0px;
    margin: 2vw 0rem 0rem 2rem;
}

.projectCardCaseButtonUnderline {
    padding-top: 5px;
    display: block;
    justify-content: right;
    /* width: vw; */
    /* width: 6vw; */
    color: red;
}

.projectCardTags {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 2.5vw 0rem 0rem 2rem;
}

.tag {
    border: 1px solid white;
    border-radius: 50px;
    padding: 5px 20px 5px 20px;
    margin: 5px 10px 0px 0px;
    text-align: center;
    display: flex;
    align-content: center;
    overflow: hidden;
}

.tagName {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 1vw;
    white-space: nowrap;
    color: white;
    text-transform: uppercase;
    margin: 0px 0px 0px 10px;
}

.dot {
    height: 1vw;
    width: 1vw;
    border-radius: 50%;
    display: inline-block;
    vertical-align: middle;
}

.comingSoon {
    height: 100vh;
    background-color: black;

    color: white;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 900;
    font-size: 5rem;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.subText {
    background-color: black;

    color: white;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    padding: 30px;

    display: flex;
    /* flex-direction: row; */
    justify-content: center;
    align-items: center;
}

.contact {
    background-color: black;
    padding: 5vw 0vw 15vw 0vw;
}

.contactTitle {
    display: flex;
    justify-content: center;

    font-family: Montserrat;
    font-style: normal;
    font-weight: 700;
    font-size: 8vw;
    padding-bottom: 5vw;
    color: white;
}

.contactDesc {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 200;
    font-size: 2vw;
    color: white;
}

.contactFieldTitle {
    display: flex !important;
    flex-direction: row;
    justify-content: space-between;
    width: 30%;
    padding: 0vw 15vw 0vw 15vw;
}

.contactField {
    align-items: left;

    font-family: Montserrat;
    font-style: normal;
    font-weight: 400;
    font-size: 2vw;
    padding-bottom: 1vw;
    color: white;
}

