.landingBackgroundProject {
    position: relative;
}

.projectCardContainer {
    padding: 20vw 10vw 15vw 10vw;
    display: flex;
    top: 5vw;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    z-index: 10;
}

.projects {
    padding: 5vw 5vw 0px 10vw;
    position: absolute;
    right: 0px;
    display: grid;
    grid-gap: 3vw 3vw;
    align-items: center;
    grid-template-columns: 80px 80px 80px 80px 80px;
    grid-template-rows: auto auto auto auto auto;
    justify-items: center;
    grid-template-areas:
        'p r o dash j'
        '. . . . e'
        '. . . . c'
        '. . . . t'
        '. . . . s'; 
    /* z-index: -5; */
}

.projects img {
    display: inline-block;
    vertical-align: middle;
}

.p {
    grid-area: p;
    width: 4vw !important;
}
.r {
    grid-area: r;
    width: 4vw !important;
}
.o {
    grid-area: o;
    width: 5vw !important;
}
.j {
    grid-area: j;
    width: 4vw !important;
}
.e {
    grid-area: e;
    width: 4vw !important;
}
.c {
    grid-area: c;
    width: 4vw !important;
}
.t {
    grid-area: t;
    width: 4vw !important;
}
.s {
    grid-area: s;
    width: 4vw !important;
}
.dash {
    grid-area: dash;
    width: 4vw !important;
}

.projectCard {
    position: relative;
    margin: 10px 10px 5rem 10px;
    z-index: 20;
}
.projectCardHover {
    position: relative;
    top: -80px;
    margin: 10px 10px 0px 10px;
    /* z-index: 20; */
}

.projectCardHoverBackground {
    position: absolute;
    left: 10px;
    top: 10px;
    width: 100%;
    height: 100%;
    background: url('../../images/redBackground.png');
    /* height: 450px; */
    z-index: -20;
}

.projectCardNumber {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 900;
    font-size: 4vw;

    text-align: center;
    letter-spacing: 0.1em;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: black;
    -webkit-text-fill-color: transparent;

    position: relative;
    text-align: right;
    top: -10px;
}

@media only screen and (max-width: 1300px) {
    .projectCardBackground {
        position: relative;
        background-color: black;
        flex-basis: 50%;
        color: white;
        height: 35vw;
        width: 20vw;
        margin: 10px 10px 4rem 10px;
    }
}

@media only screen and (min-width: 1300px) {
    .projectCardBackground {
        position: relative;
        background-color: black;
        flex-basis: 33.33%;
        color: white;
        height: 25vw;
        width: 20vw;
    }
}

.projectCardImage {
    border-radius: 50%;
    height: 50px;
    width: 50px;
    margin: 1vw 0rem 0rem 2rem;
}

.projectCardImageHover {
    border-radius: 50%;
    height: 7vw;
    width: 7vw;
    margin: 1vw 0rem 0rem 2rem;
    position: relative;
    top: -6vw;
}

.projectHoverTest {
    position: relative;
    top: -5vw;
}

.projectCardTitle {
    color: white;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 900;
    font-size: 2vw;
    line-height: 2vw;
    text-transform: uppercase;
    padding: 1vw 0rem 0rem 2rem;
    z-index: 10;
}

.projectCardDesc {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 100;
    font-size: 1vw;
    line-height: 1.5vw;
    padding: 2vw 2rem 0rem 2rem;
}

.projectCardCaseButton {
    font-family: Roboto;
    font-style: normal;
    font-weight: 300;
    /* font-size: vw;  */
    /* line-height: 23px; */
    display: block;
    background-color: #000000; /* Green */
    text-transform: lowercase;
    border: none;
    color: white;
    text-align: left;
    text-decoration: none;
    padding: 0px;
    outline: none;

    position: relative;
    bottom: 0px;
    margin: 2vw 0rem 0rem 2rem;
}

.projectCardCaseButtonUnderline {
    padding-top: 5px;
    display: block;
    justify-content: right;
    /* width: vw; */
    /* width: 6vw; */
    color: red;
}

.projectCardTags {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 2.5vw 0rem 0rem 2rem;
}

.tag {
    border: 1px solid white;
    border-radius: 50px;
    padding: 5px 20px 5px 20px;
    margin: 5px 10px 0px 0px;
    text-align: center;
    display: flex;
    align-content: center;
    overflow: hidden;
}

.tagName {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 1vw;
    white-space: nowrap;
    color: white;
    text-transform: uppercase;
    margin: 0px 0px 0px 10px;
}

.dot {
    height: 1vw;
    width: 1vw;
    border-radius: 50%;
    display: inline-block;
    vertical-align: middle;
}
