.comingSoon {
    height: 100vh;
    background-color: black;

    color: white;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 900;
    font-size: 5rem;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.subText {
    background-color: black;

    color: white;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    padding: 30px;

    display: flex;
    /* flex-direction: row; */
    justify-content: center;
    align-items: center;
}
