.workBackground {
    /* padding-top: 10vw; */
    /* height: 100vh; */
    background-color: black;
}

.workContainer {
    padding-top: 5vw;
    height: 100%;
    background-color: black;
}

.work {
    padding: 10px 0px 0px 10vw;
    display: grid;
    grid-gap: 3vw;
    align-items: center;
    grid-template-columns: 80px 80px 80px 80px;
    justify-items: center;
    grid-template-areas:
        'w . . . .'
        'o dash r k .';
}

.work img {
    display: inline-block;
    vertical-align: middle;
}

.w {
    grid-area: w;
    /* transform-style: preserve-3d;
    transition: all 1s linear; */
    width: 6vw !important;
}
/* .w:hover {
    transform: rotateY(180deg);
    grid-area: w;
} */
.o {
    grid-area: o;
    width: 5vw !important;
}
.dash {
    grid-area: dash;
    width: 5vw !important;
}
.r {
    grid-area: r;
    width: 5vw !important;
}
.k {
    grid-area: k;
    width: 4vw !important;
}

.workCarousel {
    position: relative;
    height: 50vw;
}

.workNumber {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 900;
    font-size: 10vw;
    color: white;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: white;
    -webkit-text-fill-color: transparent;
    /* display: block;  */

    display: inline-block;
    margin-left: auto;
    margin-right: auto;
    width: 58%;
    position: absolute;
    top: -22%;
}

.workDescription {
    position: relative;
    text-align: left;
    width: 100%;
    top: 10vw;
    left: 11vw;
}

.workPosition {
    font-family: Roboto;
    font-style: italic;
    font-weight: 300;
    font-size: 3vw;
    color: #ffffff;
    z-index: 5;
}

.workName {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 900;
    font-size: 10vw;
    color: #ffffff;
    position: relative;
    z-index: 5;
}

.workImage {
    width: 50vw;
    position: absolute;
    top: 3vw;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
    z-index: 0;
}

.workDots {
    /* display: inline-block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
    position: absolute;
    top: 0px */
    /* 
    left: 50%;
    top: 0px; */
}

.imageOverlay {
    background-image: url('../../images/workDotsFull.png');
    background-position: calc(50% + 18%) 90%;
    background-repeat: no-repeat;
    background-size: 40% 100%;
    min-height: 100;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 10px;
}

.workViewButton {
    font-family: Roboto;
    font-style: normal;
    font-weight: 300;
    font-size: 1vw;
    /* line-height: 23px; */
    display: block;
    background-color: #000000; /* Green */
    text-transform: lowercase;
    border: none;
    color: white;
    margin: 2vw 0px 0px 0px;
    text-align: center;
    text-decoration: none;
    padding: 0px;
    outline: none;
}

.workButtonUnderline {
    padding-top: 5px;
    display: block;
    width: 6vw;
}

.workStatus {
    display: inline-block;
    margin: 5vw 16px 0px 0px;
}

.workTooltip {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    text-align: center;
    color: #ffffff;
    margin: 0px;
}

.workStatus .workTooltip {
    visibility: hidden;
}

.workStatus:hover .workTooltip {
    visibility: visible;
}

.workCardTags {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 2.5vw 0rem 0rem 2rem;
    
    position: absolute;
    /* text-align: right; */
    /* width: 33%; */
    top: -25%;
    right: 18%;
    z-index: 10px
    /* left: 11vw; */
}

.workTag {
    border: 1px solid white;
    border-radius: 50px;
    padding: 5px 20px 5px 20px;
    margin: 5px 10px 0px 0px;
    text-align: center;
    display: flex;
    align-content: center;
    overflow: hidden;
}

.workTagName {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 1vw;
    white-space: nowrap;
    color: white;
    text-transform: uppercase;
    margin: 0px 0px 0px 10px;
}
