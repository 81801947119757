.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}





.constructionPage {
    width: 100vw;
    height: 100vh;
    background-color: #FDF9EF;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;


    font-family: Montserrat;
    font-weight: 600;
    font-size: 48px;
    color: black;
    z-index: 5;
}

.constructionPageSubtext {
    /* display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; */
    padding: 0px 30px;


    font-family: Montserrat;
    font-weight: 300;
    font-size: 24px;
    color: black;
    z-index: 5;
}

.emoji {
  font-size: 16px;
}

.strikethrough {
  position: relative;
  display: block;
  cursor: pointer;

  font-family: Montserrat;
  font-weight: 300;
  font-size: 16px;
  color: black;
  z-index: 5;
}

.strikethrough:before,
.strikethrough:after {
  content: '';
  position: absolute;
  width: 0%;
  height: 2px;
  top: 50%;
  margin-top: -2.5px;
  background: #fff;
}

.strikethrough:before {
  left: -2.5px;
}
.strikethrough:after {
  right: 2.5px;
  background: black;
  transition: width 0.8s cubic-bezier(0.22, 0.61, 0.36, 1);
}

.strikethrough:hover:before {
  background: black;
  width: 100%;
  transition: width 0.5s cubic-bezier(0.22, 0.61, 0.36, 1);
}

.strikethrough:hover:after {
  background: transparent;
  width: 100%;
  transition: 0s;
}
