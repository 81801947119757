.contact {
    background-color: black;
    padding: 5vw 0vw 15vw 0vw;
}

.contactTitle {
    display: flex;
    justify-content: center;

    font-family: Montserrat;
    font-style: normal;
    font-weight: 700;
    font-size: 8vw;
    padding-bottom: 5vw;
    color: white;
}

.contactDesc {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 200;
    font-size: 2vw;
    color: white;
}

.contactFieldTitle {
    display: flex !important;
    flex-direction: row;
    justify-content: space-between;
    width: 30%;
    padding: 0vw 15vw 0vw 15vw;
}

.contactField {
    align-items: left;

    font-family: Montserrat;
    font-style: normal;
    font-weight: 400;
    font-size: 2vw;
    padding-bottom: 1vw;
    color: white;
}
